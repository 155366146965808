import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chat',
    template: `
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1455_1177)">
                <path d="M18 4C10.2626 4 4 10.2615 4 18C4 20.4556 4.64044 22.8539 5.8566 24.9692L4.05276 30.5708C3.92694 30.9614 4.03033 31.3895 4.32043 31.6796C4.60776 31.9669 5.03479 32.0741 5.42914 31.9472L11.0308 30.1434C13.1461 31.3595 15.5444 32 18 32C25.7374 32 32 25.7385 32 18C32 10.2626 25.7385 4 18 4ZM18 29.8125C15.7819 29.8125 13.6199 29.1936 11.7475 28.0228C11.4781 27.8544 11.1436 27.8089 10.8323 27.9091L6.78863 29.2114L8.09088 25.1677C8.18957 24.8609 8.14792 24.526 7.97702 24.2525C6.80636 22.3801 6.1875 20.218 6.1875 18C6.1875 11.4866 11.4866 6.1875 18 6.1875C24.5134 6.1875 29.8125 11.4866 29.8125 18C29.8125 24.5134 24.5134 29.8125 18 29.8125ZM19.3672 18C19.3672 18.7549 18.7552 19.3672 18 19.3672C17.2448 19.3672 16.6328 18.7549 16.6328 18C16.6328 17.2448 17.2448 16.6328 18 16.6328C18.7552 16.6328 19.3672 17.2448 19.3672 18ZM24.8359 18C24.8359 18.7549 24.2239 19.3672 23.4687 19.3672C22.7136 19.3672 22.1016 18.7549 22.1016 18C22.1016 17.2448 22.7136 16.6328 23.4687 16.6328C24.2239 16.6328 24.8359 17.2448 24.8359 18ZM13.8984 18C13.8984 18.7549 13.2864 19.3672 12.5312 19.3672C11.7763 19.3672 11.1641 18.7549 11.1641 18C11.1641 17.2448 11.7763 16.6328 12.5312 16.6328C13.2864 16.6328 13.8984 17.2448 13.8984 18Z" [attr.fill]="stroke"/>
            </g>
            <defs>
                <clipPath id="clip0_1455_1177">
                    <rect width="28" height="28" fill="white" transform="translate(4 4)"/>
                </clipPath>
            </defs>
        </svg>
    `,
    styles: [`
        :host {
            --chat-color: #A9B8C6;
        }
        svg g path {
            stroke: var(--chat-color);
        }
    `]
})
export class ChatComponent {
    @Input() stroke: string = 'var(--chat-color)';
}
