<div class="box-related-content">
    <div *ngIf="author === 'ai'" class="related-content-container-wrapper">
        <div class="related-content-header">
            <p class="related-content-title">{{ relatedContentTitle }}</p>
            <div class="arrow-buttons">
                <button id="id-back-arrow" class="back-arrow-button" (click)="backContent()">
                    <app-arrow-back></app-arrow-back>
                </button>
                <button id="id-next-arrow" class="next-arrow-button" (click)="nextContent()">
                    <app-arrow-next></app-arrow-next>
                </button>
            </div>
        </div>
    </div>
    <div class="media-buttons-container" [ngClass]="{'client-media-buttons-container': author === 'client'}">
        <div class="media-buttons-flex">
            <div class="attachment-list" [style.transform]="transform">
                <button *ngFor="let attachment of attachments" id="id-media-content" type="button"
                        class="media-button" data-bs-toggle="modal" data-bs-target="#idModal"
                        (click)="emitMediaType(attachment.type, attachment.url)">
                    <div class="media-type-container">
                        <div *ngIf="attachment.type=='image'" class="image-content">
                            <img [src]="attachment.url" alt="Source Media Image">
                            <div class="image-icon">
                                <app-image></app-image>
                            </div>
                        </div>
                        <div *ngIf="attachment.type=='video'" class="video-content">
                            <video [src]="attachment.url" ></video>
                            <div class="play-video-icon">
                                <app-play></app-play>
                            </div>
                        </div>
                        <div *ngIf="attachment.type=='document'" class="pdf-content">
                            <div class="pdf-icon">
                                <div class="document-icon">
                                    <app-document></app-document>
                                    <p class="document-extension">{{ getAttachmentExtension(attachment) }}</p>
                                </div>
                                <p class="document-name">{{ attachment.name }}</p>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</div>
