import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";

import { EventService } from "../../../core/services/event.service";
import { LanguageService } from "../../../core/services/language.service";
import { MessageService } from "../../../core/services/message.service";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../core/services/alert.service";
import { PasswordModel } from "../../../core/models/loginModel";

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
    styleUrl: './change-password-modal.component.scss'
})
export class ChangePasswordModalComponent implements OnInit, OnDestroy {
    @Input() idModal!: string;
    @Input() labelModal!: string;

    showImage: string = 'assets/icons/show.svg';
    changePasswordTitle: string = 'Change Password?';
    currentPasswordLabel: string = 'Current Password';
    newPasswordLabel: string = 'New Password';
    confirmPasswordLabel: string = 'Confirm New Password';
    changePasswordPlaceholder: string = 'Password';
    changePasswordButton: string = 'Confirm';

    translateSubscription!: Subscription;
    changePasswordForm!: FormGroup; // Using reactive forms

    passwordFields = [
        { id: 'id-current-password', label: 'Current Password', control: 'currentPassword', show: false },
        { id: 'id-new-password', label: 'New Password', control: 'newPassword', show: false },
        { id: 'id-confirm-password', label: 'Confirm New Password', control: 'confirmPassword', show: false },
    ];

    errorRequiredMessage = 'is required.'
    errorMismatchMessage: string = 'Passwords do not match.'

    constructor(
        private formBuilder: FormBuilder,
        private event: EventService,
        private language: LanguageService,
        private messages: MessageService,
        private auth: AuthService,
        private alert: AlertService) { }

    ngOnInit() {
        this.translateSubscription = this.language.selectedLanguage$.subscribe((selectedLanguage) => {
            const translate = this.language.getDesignTranslation(selectedLanguage.locale)
            Object.assign(this, translate.typography);

            this.passwordFields.forEach(field => {
                if (field.control === 'currentPassword') {
                    field.label = translate.typography.currentPasswordLabel || 'Current Password';
                } else if (field.control === 'newPassword') {
                    field.label = translate.typography.newPasswordLabel || 'New Password';
                } else if (field.control === 'confirmPassword') {
                    field.label = translate.typography.confirmPasswordLabel || 'Confirm New Password';
                }
            });
        });

        this.initializeForm();
    }

    private initializeForm() {
        this.changePasswordForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            confirmPassword: ['', Validators.required],
        }, { validator: this.passwordMatchValidator });
    }

    getChangePasswordFormElement(control: string) {
        return this.changePasswordForm.get(control);
    }

    isFieldInvalid(controlName: string): boolean {
        const control = this.changePasswordForm.get(controlName);
        return !!(control?.invalid && (control.dirty || this.changePasswordForm.get(controlName)?.touched)); // Parse into boolean type with !!
    }

    get isFormInvalid(): boolean {
        return this.changePasswordForm.invalid;
    }

    passwordMatchValidator(formGroup: FormGroup) {
        const newPassword = formGroup.get('newPassword');
        const confirmPassword = formGroup.get('confirmPassword');

        if (newPassword && confirmPassword && newPassword.value !== confirmPassword.value) {
            confirmPassword.setErrors({ mismatch: true });
        } else {
            confirmPassword?.setErrors(null);
        }
    }

    closeChangePassword() {
        this.resetFields();
        this.event.blurEfectEvent.emit(false);
    }

    resetFields(){
        this.changePasswordForm.reset();
    }

    togglePasswordVisibility(field: any) {
        field.show = !field.show;
    }

    async confirmChangePassword() {
        this.alert.showLoading();

        const passwordModel: PasswordModel = {
            oldPassword: this.getChangePasswordFormElement('currentPassword')?.value,
            newPassword: this.getChangePasswordFormElement('newPassword')?.value,
            configPassword: this.getChangePasswordFormElement('confirmPassword')?.value
        };

        const response = await this.auth.changePassword(passwordModel);

        if (response.status !== 200) {
            this.alert.showError('Error', response.message);
            return;
        }

        this.closeChangePassword();
        this.alert.close();
        this.alert.showSucess('Password Changed!', 'Your password has been successfully changed.');
    }

    ngOnDestroy() {
        if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    }
}
