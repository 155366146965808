import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import StreamingAvatar, { AvatarQuality, StreamingEvents, TaskType } from '@heygen/streaming-avatar';

@Injectable({
    providedIn: 'root',
})
export class HeyGenService {
    // Bojan key
    //private heyGenKey: string = 'NGIwN2VmNjc2Y2EzNDZiMWI5OTg2YTljNmZkNGQ4ZjYtMTY4NDQ4NTE4MA==';
    //private heyGenKey: string = 'MTE2MDUxYzJhYTAzNDE3NGFkMmJjNDQ5ZmQxMDIzNTEtMTczMjU0ODAyMg=='
    //private heyGenKey: string = 'NDUzMzhlOWI4NjE4NGU5NGJlYzA2MDc5MGVhNzgwOGEtMTczMjEwNjQyOQ==';

    // Paid heyGen key
    private heyGenKey: string = 'MTZlZjRmNGEwOTg2NDZmMTg0MzM2NDRmZjIxZTBhZDctMTczMjcyMjA0Mg==';

    private avatar: StreamingAvatar | null = null;
    private sessionData: any;
    public isAvatarSpeaking = new BehaviorSubject<boolean>(false);
    private avatarVideoSubject = new Subject<HTMLVideoElement>();
    private avatarCanvasSubject = new Subject<HTMLCanvasElement>();
    private streamReadySubject = new BehaviorSubject<any>(null);

    currentVideoElement!: HTMLVideoElement;
    currentCanvasElement!: HTMLCanvasElement;

    constructor() {
        this.getAvatarVideo().subscribe((video) => {
            this.currentVideoElement = video;
        });
        this.getAvatarCanvas().subscribe((canvas) => {
            this.currentCanvasElement = canvas;
        });
    }

    // getters
    getHeyGenApiKey() {
        return this.heyGenKey;
    }

    getAvatarVideo() {
        return this.avatarVideoSubject.asObservable();
    }

    getAvatarCanvas() {
        return this.avatarCanvasSubject.asObservable();
    }

    getStreamReady() {
        return this.streamReadySubject.asObservable();
    }

    setAvatarVideo(video: HTMLVideoElement) {
        this.avatarVideoSubject.next(video);
    }

    setAvatarCanvas(canvas: HTMLCanvasElement) {
        this.avatarCanvasSubject.next(canvas);
    }

    setStreamReady(ready: any) {
        this.streamReadySubject.next(ready)
    }

    async fetchAccessToken(): Promise<string> {
        const apiKey = this.getHeyGenApiKey();
        const response = await fetch(
            "https://api.heygen.com/v1/streaming.create_token",
            {
                method: "POST",
                headers: { "x-api-key": apiKey },
            }
        );

        const { data } = await response.json();
        return data.token;
    }

    async initializeAvatarSession(avatarConfig: any) {
        try {
            const token = await this.fetchAccessToken();
            this.avatar = new StreamingAvatar({ token });

            this.sessionData = await this.avatar.createStartAvatar({
                quality: AvatarQuality.High,
                avatarName: avatarConfig.name,
                voice: {
                    voiceId: avatarConfig.voiceId,
                }
            });

            return { avatar: this.avatar, sessionData: this.sessionData };
        } catch (error) {
            console.error("Error initializing avatar session:", error);
            throw error;
        }
    }

    async terminateAvatarSession(videoElement: HTMLVideoElement) {
        if (!this.avatar || !this.sessionData) return;

        await this.avatar.stopAvatar();
        videoElement.srcObject = null;
        this.avatar = null;
    }

    startChromaKeying() {
        const context = this.currentCanvasElement.getContext("2d");

        if (!context) {
            console.error("Canvas context not available");
            return;
        }

        this.currentCanvasElement.width = this.currentVideoElement.videoWidth;
        this.currentCanvasElement.height = this.currentVideoElement.videoHeight;

        const renderFrame = () => {
            context.drawImage(this.currentVideoElement, 0, 0, this.currentCanvasElement.width, this.currentCanvasElement.height);
            const imageData = context.getImageData(0, 0, this.currentCanvasElement.width, this.currentCanvasElement.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                const g = data[i + 1];
                const r = data[i];
                const b = data[i + 2];

                if (g > 100 && g > r + 50 && g > b + 50) {
                    data[i + 3] = 0;
                }
            }

            context.putImageData(imageData, 0, 0);

            if (this.currentCanvasElement.style.visibility === 'hidden') {
                this.currentCanvasElement.style.visibility = 'visible';
            }

            requestAnimationFrame(renderFrame);
        };

        renderFrame();
    }

    async handleSpeak(text: string) {
        if (this.avatar && text) {
            this.isAvatarSpeaking.next(true);

            await this.avatar.speak({
                text: text,
                task_type: TaskType.REPEAT
            });
        }
    }

    onStreamReady(callback: (event: any) => void) {
        this.avatar?.on(StreamingEvents.STREAM_READY, callback);
    }

    onSpeakCompleted(callback: (event: any) => void) {
        this.avatar?.on(StreamingEvents.AVATAR_STOP_TALKING, callback);
    }

    onStreamDisconnected(callback: () => void) {
        this.avatar?.on(StreamingEvents.STREAM_DISCONNECTED, callback);
    }
}
