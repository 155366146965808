<div class="box-header">
    <div class="header-wrapper">
        <div class="title-container">
            <button id="id-new-chat" type="button" class="new-chat-button"
                    (click)="newChat()" [disabled]="!isInteractionAllowed || talkStarted">
                <app-chat></app-chat>
            </button>
            <p class="company-name">{{ companyName }}</p>
        </div>
        <div class="inline-header">
            <app-account></app-account>
            <app-languages></app-languages>
        </div>
    </div>
    <!--<hr class="line">-->
</div>
