import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class EventService {
    blurEfectEvent: EventEmitter<any> = new EventEmitter();
    scrollToBottomEvent: EventEmitter<any> = new EventEmitter();
    dislikeButtonClicked: EventEmitter<number> = new EventEmitter();
    feedbackSubmitted: EventEmitter<number> = new EventEmitter();
    isTalkStarted = new BehaviorSubject<boolean>(false);
    isEditing = new BehaviorSubject<boolean>(false);
    isTextConversationStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isVoiceConversationStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isShowAvatarEnable: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() { }

    // getters
    getStartedTalk(): Observable<boolean> {
        return this.isTalkStarted;
    }

    getStartedEdit(): Observable<boolean> {
        return this.isEditing;
    }

    getStartedTextConversation(): Observable<boolean> {
        return this.isTextConversationStarted;
    }

    getStartedVoiceConversation(): Observable<boolean> {
        return this.isVoiceConversationStarted;
    }

    getShowAvatarEnable(): Observable<boolean> {
        return this.isShowAvatarEnable;
    }

    // setters
    setStartedTalk(isStarted: boolean): void {
        this.isTalkStarted.next(isStarted);
    }

    setStartedEdit(isStarted: boolean): void {
        this.isEditing.next(isStarted);
    }

    setStartedTextConversation(isStarted: boolean): void {
        this.isTextConversationStarted.next(isStarted);
    }

    setStartedVoiceConversation(isStarted: boolean): void {
        this.isVoiceConversationStarted.next(isStarted);
    }

    setShowAvatarEnable(isStarted: boolean): void {
        this.isShowAvatarEnable.next(isStarted);
    }

    // events
    addEvent (i: WebSocket, e: any, callable: any) {
        i.addEventListener(e, callable);
    }

    removeEvent(i: WebSocket, e: any, callable: any) {
        i.removeEventListener(e, callable);
    }
}
