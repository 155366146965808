"use strict";

module.exports = encoder;
var Enum = require("./enum"),
  types = require("./types"),
  util = require("./util");

/**
 * Generates a partial message type encoder.
 * @param {Codegen} gen Codegen instance
 * @param {Field} field Reflected field
 * @param {number} fieldIndex Field index
 * @param {string} ref Variable reference
 * @returns {Codegen} Codegen instance
 * @ignore
 */
function genTypePartial(gen, field, fieldIndex, ref) {
  return field.resolvedType.group ? gen("types[%i].encode(%s,w.uint32(%i)).uint32(%i)", fieldIndex, ref, (field.id << 3 | 3) >>> 0, (field.id << 3 | 4) >>> 0) : gen("types[%i].encode(%s,w.uint32(%i).fork()).ldelim()", fieldIndex, ref, (field.id << 3 | 2) >>> 0);
}

/**
 * Generates an encoder specific to the specified message type.
 * @param {Type} mtype Message type
 * @returns {Codegen} Codegen instance
 */
function encoder(mtype) {
  /* eslint-disable no-unexpected-multiline, block-scoped-var, no-redeclare */
  var gen = util.codegen(["m", "w"], mtype.name + "$encode")("if(!w)")("w=Writer.create()");
  var i, ref;

  // "when a message is serialized its known fields should be written sequentially by field number"
  var fields = /* initializes */mtype.fieldsArray.slice().sort(util.compareFieldsById);
  for (var i = 0; i < fields.length; ++i) {
    var field = fields[i].resolve(),
      index = mtype._fieldsArray.indexOf(field),
      type = field.resolvedType instanceof Enum ? "int32" : field.type,
      wireType = types.basic[type];
    ref = "m" + util.safeProp(field.name);

    // Map fields
    if (field.map) {
      gen("if(%s!=null&&Object.hasOwnProperty.call(m,%j)){", ref, field.name) // !== undefined && !== null
      ("for(var ks=Object.keys(%s),i=0;i<ks.length;++i){", ref)("w.uint32(%i).fork().uint32(%i).%s(ks[i])", (field.id << 3 | 2) >>> 0, 8 | types.mapKey[field.keyType], field.keyType);
      if (wireType === undefined) gen("types[%i].encode(%s[ks[i]],w.uint32(18).fork()).ldelim().ldelim()", index, ref); // can't be groups
      else gen(".uint32(%i).%s(%s[ks[i]]).ldelim()", 16 | wireType, type, ref);
      gen("}")("}");

      // Repeated fields
    } else if (field.repeated) {
      gen("if(%s!=null&&%s.length){", ref, ref); // !== undefined && !== null

      // Packed repeated
      if (field.packed && types.packed[type] !== undefined) {
        gen("w.uint32(%i).fork()", (field.id << 3 | 2) >>> 0)("for(var i=0;i<%s.length;++i)", ref)("w.%s(%s[i])", type, ref)("w.ldelim()");

        // Non-packed
      } else {
        gen("for(var i=0;i<%s.length;++i)", ref);
        if (wireType === undefined) genTypePartial(gen, field, index, ref + "[i]");else gen("w.uint32(%i).%s(%s[i])", (field.id << 3 | wireType) >>> 0, type, ref);
      }
      gen("}");

      // Non-repeated
    } else {
      if (field.optional) gen("if(%s!=null&&Object.hasOwnProperty.call(m,%j))", ref, field.name); // !== undefined && !== null

      if (wireType === undefined) genTypePartial(gen, field, index, ref);else gen("w.uint32(%i).%s(%s)", (field.id << 3 | wireType) >>> 0, type, ref);
    }
  }
  return gen("return w");
  /* eslint-enable no-unexpected-multiline, block-scoped-var, no-redeclare */
}