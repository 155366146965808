"use strict";

module.exports = Field;

// extends ReflectionObject
var ReflectionObject = require("./object");
((Field.prototype = Object.create(ReflectionObject.prototype)).constructor = Field).className = "Field";
var Enum = require("./enum"),
  types = require("./types"),
  util = require("./util");
var Type; // cyclic

var ruleRe = /^required|optional|repeated$/;

/**
 * Constructs a new message field instance. Note that {@link MapField|map fields} have their own class.
 * @name Field
 * @classdesc Reflected message field.
 * @extends FieldBase
 * @constructor
 * @param {string} name Unique name within its namespace
 * @param {number} id Unique id within its namespace
 * @param {string} type Value type
 * @param {string|Object.<string,*>} [rule="optional"] Field rule
 * @param {string|Object.<string,*>} [extend] Extended type if different from parent
 * @param {Object.<string,*>} [options] Declared options
 */

/**
 * Constructs a field from a field descriptor.
 * @param {string} name Field name
 * @param {IField} json Field descriptor
 * @returns {Field} Created field
 * @throws {TypeError} If arguments are invalid
 */
Field.fromJSON = function fromJSON(name, json) {
  return new Field(name, json.id, json.type, json.rule, json.extend, json.options, json.comment);
};

/**
 * Not an actual constructor. Use {@link Field} instead.
 * @classdesc Base class of all reflected message fields. This is not an actual class but here for the sake of having consistent type definitions.
 * @exports FieldBase
 * @extends ReflectionObject
 * @constructor
 * @param {string} name Unique name within its namespace
 * @param {number} id Unique id within its namespace
 * @param {string} type Value type
 * @param {string|Object.<string,*>} [rule="optional"] Field rule
 * @param {string|Object.<string,*>} [extend] Extended type if different from parent
 * @param {Object.<string,*>} [options] Declared options
 * @param {string} [comment] Comment associated with this field
 */
function Field(name, id, type, rule, extend, options, comment) {
  if (util.isObject(rule)) {
    comment = extend;
    options = rule;
    rule = extend = undefined;
  } else if (util.isObject(extend)) {
    comment = options;
    options = extend;
    extend = undefined;
  }
  ReflectionObject.call(this, name, options);
  if (!util.isInteger(id) || id < 0) throw TypeError("id must be a non-negative integer");
  if (!util.isString(type)) throw TypeError("type must be a string");
  if (rule !== undefined && !ruleRe.test(rule = rule.toString().toLowerCase())) throw TypeError("rule must be a string rule");
  if (extend !== undefined && !util.isString(extend)) throw TypeError("extend must be a string");

  /**
   * Field rule, if any.
   * @type {string|undefined}
   */
  if (rule === "proto3_optional") {
    rule = "optional";
  }
  this.rule = rule && rule !== "optional" ? rule : undefined; // toJSON

  /**
   * Field type.
   * @type {string}
   */
  this.type = type; // toJSON

  /**
   * Unique field id.
   * @type {number}
   */
  this.id = id; // toJSON, marker

  /**
   * Extended type if different from parent.
   * @type {string|undefined}
   */
  this.extend = extend || undefined; // toJSON

  /**
   * Whether this field is required.
   * @type {boolean}
   */
  this.required = rule === "required";

  /**
   * Whether this field is optional.
   * @type {boolean}
   */
  this.optional = !this.required;

  /**
   * Whether this field is repeated.
   * @type {boolean}
   */
  this.repeated = rule === "repeated";

  /**
   * Whether this field is a map or not.
   * @type {boolean}
   */
  this.map = false;

  /**
   * Message this field belongs to.
   * @type {Type|null}
   */
  this.message = null;

  /**
   * OneOf this field belongs to, if any,
   * @type {OneOf|null}
   */
  this.partOf = null;

  /**
   * The field type's default value.
   * @type {*}
   */
  this.typeDefault = null;

  /**
   * The field's default value on prototypes.
   * @type {*}
   */
  this.defaultValue = null;

  /**
   * Whether this field's value should be treated as a long.
   * @type {boolean}
   */
  this.long = util.Long ? types.long[type] !== undefined : /* istanbul ignore next */false;

  /**
   * Whether this field's value is a buffer.
   * @type {boolean}
   */
  this.bytes = type === "bytes";

  /**
   * Resolved type if not a basic type.
   * @type {Type|Enum|null}
   */
  this.resolvedType = null;

  /**
   * Sister-field within the extended type if a declaring extension field.
   * @type {Field|null}
   */
  this.extensionField = null;

  /**
   * Sister-field within the declaring namespace if an extended field.
   * @type {Field|null}
   */
  this.declaringField = null;

  /**
   * Internally remembers whether this field is packed.
   * @type {boolean|null}
   * @private
   */
  this._packed = null;

  /**
   * Comment for this field.
   * @type {string|null}
   */
  this.comment = comment;
}

/**
 * Determines whether this field is packed. Only relevant when repeated and working with proto2.
 * @name Field#packed
 * @type {boolean}
 * @readonly
 */
Object.defineProperty(Field.prototype, "packed", {
  get: function () {
    // defaults to packed=true if not explicity set to false
    if (this._packed === null) this._packed = this.getOption("packed") !== false;
    return this._packed;
  }
});

/**
 * @override
 */
Field.prototype.setOption = function setOption(name, value, ifNotSet) {
  if (name === "packed")
    // clear cached before setting
    this._packed = null;
  return ReflectionObject.prototype.setOption.call(this, name, value, ifNotSet);
};

/**
 * Field descriptor.
 * @interface IField
 * @property {string} [rule="optional"] Field rule
 * @property {string} type Field type
 * @property {number} id Field id
 * @property {Object.<string,*>} [options] Field options
 */

/**
 * Extension field descriptor.
 * @interface IExtensionField
 * @extends IField
 * @property {string} extend Extended type
 */

/**
 * Converts this field to a field descriptor.
 * @param {IToJSONOptions} [toJSONOptions] JSON conversion options
 * @returns {IField} Field descriptor
 */
Field.prototype.toJSON = function toJSON(toJSONOptions) {
  var keepComments = toJSONOptions ? Boolean(toJSONOptions.keepComments) : false;
  return util.toObject(["rule", this.rule !== "optional" && this.rule || undefined, "type", this.type, "id", this.id, "extend", this.extend, "options", this.options, "comment", keepComments ? this.comment : undefined]);
};

/**
 * Resolves this field's type references.
 * @returns {Field} `this`
 * @throws {Error} If any reference cannot be resolved
 */
Field.prototype.resolve = function resolve() {
  if (this.resolved) return this;
  if ((this.typeDefault = types.defaults[this.type]) === undefined) {
    // if not a basic type, resolve it
    this.resolvedType = (this.declaringField ? this.declaringField.parent : this.parent).lookupTypeOrEnum(this.type);
    if (this.resolvedType instanceof Type) this.typeDefault = null;else
      // instanceof Enum
      this.typeDefault = this.resolvedType.values[Object.keys(this.resolvedType.values)[0]]; // first defined
  } else if (this.options && this.options.proto3_optional) {
    // proto3 scalar value marked optional; should default to null
    this.typeDefault = null;
  }

  // use explicitly set default value if present
  if (this.options && this.options["default"] != null) {
    this.typeDefault = this.options["default"];
    if (this.resolvedType instanceof Enum && typeof this.typeDefault === "string") this.typeDefault = this.resolvedType.values[this.typeDefault];
  }

  // remove unnecessary options
  if (this.options) {
    if (this.options.packed === true || this.options.packed !== undefined && this.resolvedType && !(this.resolvedType instanceof Enum)) delete this.options.packed;
    if (!Object.keys(this.options).length) this.options = undefined;
  }

  // convert to internal data type if necesssary
  if (this.long) {
    this.typeDefault = util.Long.fromNumber(this.typeDefault, this.type.charAt(0) === "u");

    /* istanbul ignore else */
    if (Object.freeze) Object.freeze(this.typeDefault); // long instances are meant to be immutable anyway (i.e. use small int cache that even requires it)
  } else if (this.bytes && typeof this.typeDefault === "string") {
    var buf;
    if (util.base64.test(this.typeDefault)) util.base64.decode(this.typeDefault, buf = util.newBuffer(util.base64.length(this.typeDefault)), 0);else util.utf8.write(this.typeDefault, buf = util.newBuffer(util.utf8.length(this.typeDefault)), 0);
    this.typeDefault = buf;
  }

  // take special care of maps and repeated fields
  if (this.map) this.defaultValue = util.emptyObject;else if (this.repeated) this.defaultValue = util.emptyArray;else this.defaultValue = this.typeDefault;

  // ensure proper value on prototype
  if (this.parent instanceof Type) this.parent.ctor.prototype[this.name] = this.defaultValue;
  return ReflectionObject.prototype.resolve.call(this);
};

/**
 * Decorator function as returned by {@link Field.d} and {@link MapField.d} (TypeScript).
 * @typedef FieldDecorator
 * @type {function}
 * @param {Object} prototype Target prototype
 * @param {string} fieldName Field name
 * @returns {undefined}
 */

/**
 * Field decorator (TypeScript).
 * @name Field.d
 * @function
 * @param {number} fieldId Field id
 * @param {"double"|"float"|"int32"|"uint32"|"sint32"|"fixed32"|"sfixed32"|"int64"|"uint64"|"sint64"|"fixed64"|"sfixed64"|"string"|"bool"|"bytes"|Object} fieldType Field type
 * @param {"optional"|"required"|"repeated"} [fieldRule="optional"] Field rule
 * @param {T} [defaultValue] Default value
 * @returns {FieldDecorator} Decorator function
 * @template T extends number | number[] | Long | Long[] | string | string[] | boolean | boolean[] | Uint8Array | Uint8Array[] | Buffer | Buffer[]
 */
Field.d = function decorateField(fieldId, fieldType, fieldRule, defaultValue) {
  // submessage: decorate the submessage and use its name as the type
  if (typeof fieldType === "function") fieldType = util.decorateType(fieldType).name;

  // enum reference: create a reflected copy of the enum and keep reuseing it
  else if (fieldType && typeof fieldType === "object") fieldType = util.decorateEnum(fieldType).name;
  return function fieldDecorator(prototype, fieldName) {
    util.decorateType(prototype.constructor).add(new Field(fieldName, fieldId, fieldType, fieldRule, {
      "default": defaultValue
    }));
  };
};

/**
 * Field decorator (TypeScript).
 * @name Field.d
 * @function
 * @param {number} fieldId Field id
 * @param {Constructor<T>|string} fieldType Field type
 * @param {"optional"|"required"|"repeated"} [fieldRule="optional"] Field rule
 * @returns {FieldDecorator} Decorator function
 * @template T extends Message<T>
 * @variation 2
 */
// like Field.d but without a default value

// Sets up cyclic dependencies (called in index-light)
Field._configure = function configure(Type_) {
  Type = Type_;
};