<div class="modal fade bd-example-modal-lg" data-bs-backdrop='static' tabindex="-1" role="dialog" aria-hidden="true"
     [id]="idModal" [attr.aria-labelledby]="labelModal" (keydown.escape)="closeChangePassword()">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="change-password-title">{{ changePasswordTitle }}</p>
                <button id="id-close-change-password" class="change-password-close" data-bs-dismiss="modal" type="button" aria-label="Close" (click)="closeChangePassword()">
                    <app-close></app-close>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="changePasswordForm">
                        <div *ngFor="let field of passwordFields" class="password-container">
                            <p>{{ field.label }}</p>
                            <div class="password-input" [class.error]="isFieldInvalid(field.control)">
                                <input [id]="field.id" [type]="field.show ? 'text' : 'password'" [placeholder]="changePasswordPlaceholder"
                                       [formControlName]="field.control" required />
                                <button *ngIf="getChangePasswordFormElement(field.control)?.value" type="button" (click)="togglePasswordVisibility(field)">
                                    <img [src]="showImage" alt="Show icon">
                                </button>
                            </div>
                            <div *ngIf="isFieldInvalid(field.control)">
                                <mat-error *ngIf="getChangePasswordFormElement(field.control)?.hasError('required')">
                                    {{ field.label + ' ' + errorRequiredMessage }}
                                </mat-error>
                                <mat-error *ngIf="field.control === 'confirmPassword' && getChangePasswordFormElement(field.control)?.hasError('mismatch')">
                                    {{ errorMismatchMessage }}
                                </mat-error>
                            </div>
                        </div>
                </form>
            </div>
            <div class="modal-footer">
                <button id="id-change-password" data-bs-dismiss="modal" class="submit-change-password" type="button"
                        (click)="confirmChangePassword()" [disabled]="isFormInvalid">{{ changePasswordButton }}</button>
            </div>
        </div>
    </div>
</div>
