"use strict";

/**
 * Common type constants.
 * @namespace
 */
var types = exports;
var util = require("./util");
var s = ["double",
// 0
"float",
// 1
"int32",
// 2
"uint32",
// 3
"sint32",
// 4
"fixed32",
// 5
"sfixed32",
// 6
"int64",
// 7
"uint64",
// 8
"sint64",
// 9
"fixed64",
// 10
"sfixed64",
// 11
"bool",
// 12
"string",
// 13
"bytes" // 14
];
function bake(values, offset) {
  var i = 0,
    o = {};
  offset |= 0;
  while (i < values.length) o[s[i + offset]] = values[i++];
  return o;
}

/**
 * Basic type wire types.
 * @type {Object.<string,number>}
 * @const
 * @property {number} double=1 Fixed64 wire type
 * @property {number} float=5 Fixed32 wire type
 * @property {number} int32=0 Varint wire type
 * @property {number} uint32=0 Varint wire type
 * @property {number} sint32=0 Varint wire type
 * @property {number} fixed32=5 Fixed32 wire type
 * @property {number} sfixed32=5 Fixed32 wire type
 * @property {number} int64=0 Varint wire type
 * @property {number} uint64=0 Varint wire type
 * @property {number} sint64=0 Varint wire type
 * @property {number} fixed64=1 Fixed64 wire type
 * @property {number} sfixed64=1 Fixed64 wire type
 * @property {number} bool=0 Varint wire type
 * @property {number} string=2 Ldelim wire type
 * @property {number} bytes=2 Ldelim wire type
 */
types.basic = bake([/* double   */1, /* float    */5, /* int32    */0, /* uint32   */0, /* sint32   */0, /* fixed32  */5, /* sfixed32 */5, /* int64    */0, /* uint64   */0, /* sint64   */0, /* fixed64  */1, /* sfixed64 */1, /* bool     */0, /* string   */2, /* bytes    */2]);

/**
 * Basic type defaults.
 * @type {Object.<string,*>}
 * @const
 * @property {number} double=0 Double default
 * @property {number} float=0 Float default
 * @property {number} int32=0 Int32 default
 * @property {number} uint32=0 Uint32 default
 * @property {number} sint32=0 Sint32 default
 * @property {number} fixed32=0 Fixed32 default
 * @property {number} sfixed32=0 Sfixed32 default
 * @property {number} int64=0 Int64 default
 * @property {number} uint64=0 Uint64 default
 * @property {number} sint64=0 Sint32 default
 * @property {number} fixed64=0 Fixed64 default
 * @property {number} sfixed64=0 Sfixed64 default
 * @property {boolean} bool=false Bool default
 * @property {string} string="" String default
 * @property {Array.<number>} bytes=Array(0) Bytes default
 * @property {null} message=null Message default
 */
types.defaults = bake([/* double   */0, /* float    */0, /* int32    */0, /* uint32   */0, /* sint32   */0, /* fixed32  */0, /* sfixed32 */0, /* int64    */0, /* uint64   */0, /* sint64   */0, /* fixed64  */0, /* sfixed64 */0, /* bool     */false, /* string   */"", /* bytes    */util.emptyArray, /* message  */null]);

/**
 * Basic long type wire types.
 * @type {Object.<string,number>}
 * @const
 * @property {number} int64=0 Varint wire type
 * @property {number} uint64=0 Varint wire type
 * @property {number} sint64=0 Varint wire type
 * @property {number} fixed64=1 Fixed64 wire type
 * @property {number} sfixed64=1 Fixed64 wire type
 */
types.long = bake([/* int64    */0, /* uint64   */0, /* sint64   */0, /* fixed64  */1, /* sfixed64 */1], 7);

/**
 * Allowed types for map keys with their associated wire type.
 * @type {Object.<string,number>}
 * @const
 * @property {number} int32=0 Varint wire type
 * @property {number} uint32=0 Varint wire type
 * @property {number} sint32=0 Varint wire type
 * @property {number} fixed32=5 Fixed32 wire type
 * @property {number} sfixed32=5 Fixed32 wire type
 * @property {number} int64=0 Varint wire type
 * @property {number} uint64=0 Varint wire type
 * @property {number} sint64=0 Varint wire type
 * @property {number} fixed64=1 Fixed64 wire type
 * @property {number} sfixed64=1 Fixed64 wire type
 * @property {number} bool=0 Varint wire type
 * @property {number} string=2 Ldelim wire type
 */
types.mapKey = bake([/* int32    */0, /* uint32   */0, /* sint32   */0, /* fixed32  */5, /* sfixed32 */5, /* int64    */0, /* uint64   */0, /* sint64   */0, /* fixed64  */1, /* sfixed64 */1, /* bool     */0, /* string   */2], 2);

/**
 * Allowed types for packed repeated fields with their associated wire type.
 * @type {Object.<string,number>}
 * @const
 * @property {number} double=1 Fixed64 wire type
 * @property {number} float=5 Fixed32 wire type
 * @property {number} int32=0 Varint wire type
 * @property {number} uint32=0 Varint wire type
 * @property {number} sint32=0 Varint wire type
 * @property {number} fixed32=5 Fixed32 wire type
 * @property {number} sfixed32=5 Fixed32 wire type
 * @property {number} int64=0 Varint wire type
 * @property {number} uint64=0 Varint wire type
 * @property {number} sint64=0 Varint wire type
 * @property {number} fixed64=1 Fixed64 wire type
 * @property {number} sfixed64=1 Fixed64 wire type
 * @property {number} bool=0 Varint wire type
 */
types.packed = bake([/* double   */1, /* float    */5, /* int32    */0, /* uint32   */0, /* sint32   */0, /* fixed32  */5, /* sfixed32 */5, /* int64    */0, /* uint64   */0, /* sint64   */0, /* fixed64  */1, /* sfixed64 */1, /* bool     */0]);