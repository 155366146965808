import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-linkedin',
    template: `
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Linkedin" clip-path="url(#clip0_1447_1466)">
                <path id="Vector" d="M10.0005 26.4375H5.62549V12.3125H10.0005V26.4375ZM10.437 7.81223C10.437 6.39792 9.28955 5.24997 7.87598 5.24997C6.45703 5.24997 5.3125 6.39792 5.3125 7.81223C5.3125 9.22702 6.45703 10.375 7.87598 10.375C9.28955 10.375 10.437 9.22702 10.437 7.81223ZM26.375 18.6665C26.375 14.8747 25.574 12.0625 21.1445 12.0625C19.0161 12.0625 17.5874 13.1269 17.0042 14.2339H17V12.3125H12.75V26.4375H17V19.4243C17 17.5876 17.4675 15.8083 19.7441 15.8083C21.9897 15.8083 22.0625 17.9087 22.0625 19.541V26.4375H26.375V18.6665ZM32 28.25V3.74997C32 1.6821 30.3179 -3.05176e-05 28.25 -3.05176e-05H3.75C1.68213 -3.05176e-05 0 1.6821 0 3.74997V28.25C0 30.3178 1.68213 32 3.75 32H28.25C30.3179 32 32 30.3178 32 28.25ZM28.25 2.49997C28.9392 2.49997 29.5 3.06076 29.5 3.74997V28.25C29.5 28.9392 28.9392 29.5 28.25 29.5H3.75C3.06079 29.5 2.5 28.9392 2.5 28.25V3.74997C2.5 3.06076 3.06079 2.49997 3.75 2.49997H28.25Z" [attr.fill]="stroke"/>
            </g>
            <defs>
                <clipPath id="clip0_1447_1466">
                    <rect width="32" height="32" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    `,
    styles: [`
        :host {
            --stroke-linkedin-color: #657A90;
        }
        svg path {
            stroke: var(--stroke-linkedin-color);
        }
    `]
})
export class LinkedinComponent {
    @Input() stroke: string = 'var(--stroke-linkedin-color)';
}
