import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor() { }

    showLoading() {
        Swal.fire({
            allowOutsideClick: false,
            background: 'rgba(255, 255, 255, 0.0)',
            backdrop: 'rgba(255, 255, 255, 0.5)',
            heightAuto: false,
            customClass: {
                popup: 'loader-popup'
            },
            html: `
                <div class="loader-container">
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p class="loading-text">Loading</p>
                </div>`,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    showError(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/error.svg',
            imageAlt: 'Custom error image',
            title: title,
            text: text,
            heightAuto: false,
            customClass: {
                popup: 'general-popup'
            }
        });
    }

    showSucess(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            confirmButtonText: 'OK',
            imageUrl: '../../../assets/icons/sucess.svg',
            imageAlt: 'Custom sucess image',
            title: title,
            text: text,
            heightAuto: false,
            customClass: {
                popup: 'general-popup'
            }
        });
    }

    close() {
        Swal.close();
    }
}
