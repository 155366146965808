export class AttachmentDto {
    name: string;
    size: number;
    url: string;
    type: string;
    file: File;

    constructor(name: string, size: number, url: string, type: string, file: File) {
        this.name = name;
        this.size = size;
        this.url = url;
        this.type = type;
        this.file = file;
    }
}
